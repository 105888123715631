






































































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import {
  StatisticsCard,
  StatisticsCardType,
  StatisticsConfig,
  StatisticType,
} from "@/models/statistics-config.model";
import draggable from "vuedraggable";
import UserFilterSelect from "@/components/admin/user-filters/UserFilterSelect.vue";

const statisticsConfigNamespace = namespace("StatisticsConfig");

@Component({ components: { draggable, UserFilterSelect } })
export default class StatisticsConfigView extends Vue {
  isLoading = false;

  @statisticsConfigNamespace.Getter("all")
  statisticsConfig!: StatisticsConfig;

  @statisticsConfigNamespace.Action("fetchAll")
  fetchstatisticsConfig!: () => Promise<void>;

  @statisticsConfigNamespace.Action("update")
  update!: (statisticsConfig: any) => Promise<void>;

  get cardTypes() {
    return Object.values(StatisticsCardType);
  }

  get statisticSources() {
    return Object.values(StatisticType);
  }

  get statisticCardType() {
    return StatisticsCardType.Statistic;
  }

  async doSave() {
    this.isLoading = true;
    await this.update(this.statisticsConfig);
    this.isLoading = false;
  }

  addCard() {
    this.statisticsConfig.cards.push(new StatisticsCard("New"));
  }

  doDelete(index: number) {
    this.statisticsConfig.cards.splice(index, 1);
  }

  async doFetchAll() {
    this.isLoading = true;
    await this.fetchstatisticsConfig();
    this.isLoading = false;
  }

  created() {
    this.doFetchAll();
  }
}
