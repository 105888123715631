










import BaseSelect from "@/components/BaseSelect.vue";
import { UserFilter } from "@/models/user-filter.model";
import { User } from "@/models/user.model";
import UserFilterService from "@/services/UserFilterService";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";

// The @Component decorator indicates the class is a Vue component
@Component({
  components: { BaseSelect },
})
export default class UserFilterSelect extends Vue {
  @VModel({ type: Array })
  data!: UserFilter[];

  @Prop()
  multiple!: boolean;

  @Prop({
    default: false,
  })
  hideLabel!: boolean;

  @Prop({
    default: false,
  })
  useId!: boolean;

  reduce(x) {
    if (this.useId) {
      return x.id;
    }
    return x;
  }

  async fetchUserFilters(): Promise<User[]> {
    return UserFilterService.fetchAllUnpaginated();
  }
}
